.particle {
    position: absolute;
    border-radius: 50%;
}

@for $i from 1 through 30 {
    @keyframes particle-animation-#{$i} {
        100% {
            transform: translate3d(
                (random(90) * 1vw),
                (random(90) * 1vh),
                (random(100) * 1px)
            );
        }
    }

    .particle:nth-child(#{$i}) {
        animation: particle-animation-#{$i} 60s infinite;
        $size: random(5) + 5 + px;
        opacity: random(100) / 100;
        height: $size;
        width: $size;
        animation-delay: -$i * 0.2s;
        transform: translate3d(
            (random(90) * 1vw),
            (random(90) * 1vh),
            (random(100) * 1px)
        );
        background: hsl(random(360), 70%, 50%);
    }
}

.enter-btn {
    background: 50% 100% / 50% 50% no-repeat
        radial-gradient(ellipse at bottom, black, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: reveal 3000ms ease-in-out forwards 200ms,
        glow 2500ms linear infinite 2000ms;

    @keyframes reveal {
        80% {
            letter-spacing: 8px;
        }
        100% {
            background-size: 300% 300%;
        }
    }
    @keyframes glow {
        40% {
            text-shadow: 0 0 8px #fff;
        }
    }
}
